// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../src/pages/aboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-ethereum-map-js": () => import("./../src/pages/ethereumMap.js" /* webpackChunkName: "component---src-pages-ethereum-map-js" */),
  "component---src-pages-graphics-js": () => import("./../src/pages/graphics.js" /* webpackChunkName: "component---src-pages-graphics-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infura-js": () => import("./../src/pages/infura.js" /* webpackChunkName: "component---src-pages-infura-js" */),
  "component---src-pages-liquality-js": () => import("./../src/pages/liquality.js" /* webpackChunkName: "component---src-pages-liquality-js" */),
  "component---src-pages-metamask-js": () => import("./../src/pages/metamask.js" /* webpackChunkName: "component---src-pages-metamask-js" */),
  "component---src-pages-omni-js": () => import("./../src/pages/omni.js" /* webpackChunkName: "component---src-pages-omni-js" */),
  "component---src-pages-tal-js": () => import("./../src/pages/TAL.js" /* webpackChunkName: "component---src-pages-tal-js" */)
}

